import { ValueOf } from "./dataTypes";

export const SITE = {
  HUL: "HUL",
  AT: "AT",
  HOS: "HOS",
} as const;

export const isHUL = () => getSite() === SITE.HUL;

export const isHOS = () => getSite() === SITE.HOS;

export const isAT = () => getSite() === SITE.AT;

export function getSite(): ValueOf<typeof SITE> | null {
  const domainName = window.location.hostname.toLowerCase();

  if (domainName === "localhost") return SITE.AT;

  if (domainName.startsWith("hul")) return SITE.HUL;

  if (domainName.startsWith("at")) return SITE.AT;

  //To eval hös, evaluation is translated into punycode: hös = xn--hs-fka
  if (domainName.startsWith("hos") || domainName.startsWith("xn--hs-fka")) return SITE.HOS;

  return null;
}
