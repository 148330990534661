import classNames from "classnames";
import { ComponentPropsWithoutRef } from "react";
import styles from "./Image.module.scss";

interface ImageProps extends ComponentPropsWithoutRef<"figure"> {
  src: string;
  alt: string;
  customCssClass?: string;
  isOrderable?: boolean;
  infoMessage?: string;
}

export default function Image({ src, alt, customCssClass, isOrderable, infoMessage, ...props }: ImageProps) {
  return (
    <figure className={classNames(styles.imgWrapper, customCssClass)} {...props}>
      <img src={src} alt={alt} className={styles.image} />
      {isOrderable === false && <div className={styles.notOrderable}>{infoMessage}</div>}
    </figure>
  );
}
