import { useTranslate } from "../../../language/i18n";
import accountService from "../../../services/account/accountService";
import SpinningButton, { SpinningButtonVariant } from "../Buttons/MuiButton/MuiButton";

const LOGOUT_BUTTON_WIDTH = 300;

const LogoutButton = ({
  variant,
  widthPX = LOGOUT_BUTTON_WIDTH,
}: {
  variant: SpinningButtonVariant;
  widthPX?: number;
}) => {
  const translate = useTranslate();

  const logout = async () => {
    await accountService.logout();
  };

  return (
    <SpinningButton widthPX={widthPX} buttonAction={logout} variant={variant}>
      {translate("LOGOUT")}
    </SpinningButton>
  );
};

export default LogoutButton;
