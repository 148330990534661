import { dialogReducer as defaultDialogReducer, sessionReducer as defaultSessionReducer } from "@myloc/myloc-utils";
import { AnyAction, combineReducers, configureStore, Reducer } from "@reduxjs/toolkit";
import { appDataReducer } from "./appData/appDataReducer";
import commonReducer from "./common/commonReducer";
import { dialogReducer, DialogState } from "./dialog/dialogReducer";
import { embeddedReducer } from "./embedded/embeddedReducer";
import { sessionReducer, SessionState } from "./session/sessionReducer";
import { RESET_SESSION } from "./session/sessionVariables";
import settingReducer from "./setting/settingReducer";

export interface RootState {
  session: ReturnType<typeof sessionReducer>;
  dialog: ReturnType<typeof dialogReducer>;
  common: ReturnType<typeof commonReducer>;
  setting: ReturnType<typeof settingReducer>;
  appData: ReturnType<typeof appDataReducer>;
  embedded: ReturnType<typeof embeddedReducer>;
}

function combine<S>(reducer1: Reducer<S>, reducer2: Reducer<S>): Reducer<S> {
  return (state, action) => {
    const nextState1 = reducer1(state as S, action);
    const nextState2 = reducer2(state as S, action);

    return { ...nextState1, ...nextState2 };
  };
}

const combinedReducer = combineReducers<RootState>({
  session: combine<SessionState>(defaultSessionReducer, sessionReducer),
  dialog: combine<DialogState>(defaultDialogReducer, dialogReducer),
  appData: appDataReducer,
  common: commonReducer,
  embedded: embeddedReducer,
  setting: settingReducer,
});

const rootReducer: Reducer<RootState, AnyAction> = (state, action) => {
  if (action.type === RESET_SESSION) {
    state = undefined;
  }

  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;

export default store;
