import { useCallback, useState } from "react";
import { useTranslate } from "../../../../language/i18n";
import { setError } from "../../../../reducers/dialog/dialogAction";

export const LOGIN_METHODS = {
  LOGIN_FORM: "LOGIN_FORM",
  BANKID: "BANKID",
} as const;

export type LoginMethod = keyof typeof LOGIN_METHODS;

const useAvailableLoginMethods = () => {
  const [availableLoginMethods, setAvailableLoginMethods] = useState<LoginMethod[]>([]);
  const translate = useTranslate();

  const isValidLoginMethod = (loginMethod: string): loginMethod is LoginMethod => {
    return loginMethod in LOGIN_METHODS;
  };

  const addLoginMethod = useCallback(
    (loginMethod: string) => {
      if (isValidLoginMethod(loginMethod) && !availableLoginMethods.includes(loginMethod)) {
        setAvailableLoginMethods(prevMethods => [...prevMethods, loginMethod]);
      }
    },
    [availableLoginMethods],
  );

  const checkValidLoginMethods = useCallback(() => {
    if (process.env.REACT_APP_LOGIN_METHODS) {
      const loginMethods = process.env.REACT_APP_LOGIN_METHODS.split(",");

      if (loginMethods.length === 0) {
        setError({ error: "exception", errorMessage: translate("NO_AUTHENTICATION_METHOD_DEFINED") });

        return;
      }

      if (loginMethods.length === 1) {
        addLoginMethod(loginMethods[0]);

        return;
      }

      loginMethods.forEach(addLoginMethod);
    } else {
      addLoginMethod(LOGIN_METHODS.LOGIN_FORM);
    }
  }, [addLoginMethod, translate]);

  return { availableLoginMethods, checkValidLoginMethods };
};

export default useAvailableLoginMethods;
