import { Button, CircularProgress } from "@mui/material";
import colors from "../../../../style/exports.module.scss";
import styles from "./style/muiButton.module.scss";

export const CONTAINED = "contained";
export const END = "end";
export const OUTLINED = "outlined";
export const START = "start";
export const TEXT = "text";

const THREE_HUNDRED = 300;

export type SpinningButtonVariant = typeof TEXT | typeof OUTLINED | typeof CONTAINED;

interface BaseButtonProps {
  buttonAction: () => void;
  loading?: boolean;
  children: React.ReactNode;
  variant?: SpinningButtonVariant;
  widthPX?: number;
}

// Icon case: Icon must be provided, iconPosition must be "start" or "end"
interface ButtonWithIconProps extends BaseButtonProps {
  icon: React.ReactNode;
  iconPosition: typeof START | typeof END;
}

// No icon case: icon and iconPosition are not allowed
interface ButtonWithoutIconProps extends BaseButtonProps {
  icon?: never;
  iconPosition?: never;
}

type ButtonComponentProps = ButtonWithIconProps | ButtonWithoutIconProps;

const SpinningButton = ({
  buttonAction,
  loading = false,
  icon,
  iconPosition,
  children,
  variant = CONTAINED,
  widthPX = THREE_HUNDRED,
}: ButtonComponentProps) => {
  return (
    <Button
      variant={variant}
      startIcon={
        !loading && icon && iconPosition === START ? <div className={styles.iconWrapper}>{icon}</div> : undefined
      }
      endIcon={!loading && icon && iconPosition === END ? <div className={styles.iconWrapper}>{icon}</div> : undefined}
      sx={{
        width: `${widthPX}px`,
        height: "40px",
        fontSize: "16px",
        textTransform: "none",
        display: "flex",
        alignItems: "center",
        backgroundColor: variant === CONTAINED ? colors.primary : undefined,
        color: variant === OUTLINED ? colors.primary : undefined,
        "&:hover": {
          backgroundColor: variant === CONTAINED ? colors.primaryDark : undefined,
        },
        marginY: 2,
        borderRadius: "8px",
      }}
      onClick={buttonAction}
      disabled={loading}
    >
      {loading ? <CircularProgress size={20} color="inherit" /> : children}
    </Button>
  );
};

export default SpinningButton;
