import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import RedirectToApplicationInfo from "../Comflow/RedirectToApplicationInfo";
import { COMFLOW, isRedirectToComflowAllowed } from "../Comflow/utils/comflowUtils";
import Page from "../shared/Page/Page";

import { FSP } from "../../utils/constants";
import { CompanyLogoList } from "./SiteAssets/SiteAssets";
import styles from "./StartPage.module.scss";
import ContentWelcome from "./Welcome/ContentWelcome";
import LoginWelcome from "./Welcome/LoginWelcome";

const StartPage = ({ desiredPage }) => {
  const session = useSelector(state => state.session);
  let history = useHistory();
  const [, setRedirectTo] = useState(null);
  const loggedIn = !!session?.sessionId;

  useEffect(() => {
    if (history?.location?.state?.from) {
      setRedirectTo(history.location.state.from.pathname);
    }
  }, [history]);

  const title = "Välkommen";
  const text =
    "Här kan du förskriva hjälpmedel till personer med funktionsnedsättning.\n\nFör att få inloggningsuppgifter till portalen ska man kontakta budget/hjälpmedelsansvarig i sin aktuella kommun.";

  //If logged in, wait for applicationsList before render
  if (loggedIn && !session?.applicationsList) return null;

  return (
    <Page customCssClass={styles.page}>
      {loggedIn ? (
        !isRedirectToComflowAllowed() || session?.applicationsList?.some(item => item.id === FSP) ? (
          <ContentWelcome customCssClass={styles.welcomeSegment} />
        ) : session?.applicationsList?.some(item => item.id === COMFLOW) ? (
          <RedirectToApplicationInfo application={session.applicationsList.find(item => item.id === COMFLOW)} />
        ) : (
          <>Unknown application</>
        )
      ) : (
        <LoginWelcome
          title={title}
          text={text}
          isLoggedIn={loggedIn}
          desiredPage={desiredPage}
          customCssClass={styles.welcomeSegment}
        />
      )}
      <CompanyLogoList />
    </Page>
  );
};

StartPage.propTypes = {
  desiredPage: PropTypes.string,
};
export default StartPage;
