import classNames from "classnames";
import hosLogo from "../../../assets/logos/HÖS/HÖS.jpg";
import mylocLogo from "../../../assets/logos/Myloc-logga.svg";
import hulLogo from "../../../assets/logos/Uppsala_logo.png";
import { getSite, SITE } from "../../../utils/siteEvaluator";
import Image from "../../shared/Image/Image";
import HÖSMunicipalityLogo from "../../shared/MunicipalityLogo/HÖSMunicipalityLogo";
import MunicipalityLogo from "../../shared/MunicipalityLogo/MunicipalityLogo";
import styles from "./SiteAssets.module.scss";

export function SiteLogo(): JSX.Element {
  const site = getSite();

  switch (site) {
    case SITE.HUL:
      return <Image src={hulLogo} alt="Uppsala - Logotyp" customCssClass={styles.imageContainer} />;
    case SITE.HOS:
      return <Image src={hosLogo} alt="Hjälpmedelscentralen - Logo" customCssClass={styles.imageContainer} />;
    default:
      return <img src={mylocLogo} alt="Myloc logo" className={styles.logo} />;
  }
}

export function CompanyLogoList() {
  const site = getSite();

  return (
    <section className={classNames(styles.list, styles.companyLogoSegment)}>
      {site === SITE.HUL ? <HULCompanyLogoList /> : site === SITE.HOS ? <HOSCompanyLogoList /> : null}
    </section>
  );
}

function HULCompanyLogoList() {
  return (
    <>
      <MunicipalityLogo id="Heby" customCssClass={styles.logo} />
      <MunicipalityLogo id="Habo" customCssClass={styles.logo} />
      <MunicipalityLogo id="Enkoping" customCssClass={styles.logo} />
      <MunicipalityLogo id="Osthammar" customCssClass={styles.logo} />
      <MunicipalityLogo id="Tierp" customCssClass={styles.logo} />
      <MunicipalityLogo id="Knivsta" customCssClass={styles.logo} />
    </>
  );
}

function HOSCompanyLogoList() {
  return (
    <>
      <HÖSMunicipalityLogo id="Kristianstad" customCssClass={styles.logo} />
      <HÖSMunicipalityLogo id="Bromölla" customCssClass={styles.logo} />
      <HÖSMunicipalityLogo id="Hässleholm" customCssClass={styles.logo} />
      <HÖSMunicipalityLogo id="Hörby" customCssClass={styles.logo} />
      <HÖSMunicipalityLogo id="Osby" customCssClass={styles.logo} />
      <HÖSMunicipalityLogo id="Simrishamn" customCssClass={styles.specialLogo} />
      <HÖSMunicipalityLogo id="ÖstraGöinge" customCssClass={styles.logo} />
      <HÖSMunicipalityLogo id="Tomelilla" customCssClass={styles.logoNoWidth} />
      <HÖSMunicipalityLogo id="Ystad" customCssClass={styles.logo} />
      <HÖSMunicipalityLogo id="Sjöbo" customCssClass={styles.specialLogo} />
      <HÖSMunicipalityLogo id="Skurup" customCssClass={styles.specialLogo} />
    </>
  );
}
