import { memo } from "react";
import BromollaFull from "../../../assets/logos/HÖS/Bromolla_full.png";
import HassleholmFull from "../../../assets/logos/HÖS/Hassleholm_full.png";
import HorbyFull from "../../../assets/logos/HÖS/Horby_full.png";
import KristianstadFull from "../../../assets/logos/HÖS/Kristianstad_full.png";
import OsbyFull from "../../../assets/logos/HÖS/Osby_full.png";
import OstraGoingeFull from "../../../assets/logos/HÖS/OstraGoinge_full.png";
import SimrishamnFull from "../../../assets/logos/HÖS/Simrishamn_full.png";
import SjoboFull from "../../../assets/logos/HÖS/Sjobo_full.png";
import SkurupFull from "../../../assets/logos/HÖS/Skurup_full.png";
import TomelillaFull from "../../../assets/logos/HÖS/Tomelilla_full.png";
import YstadFull from "../../../assets/logos/HÖS/Ystad_full.png";

const logos = [
  {
    id: "Bromölla",
    alt: "Bromölla kommun",
    fullLogo: BromollaFull,
    logo: "",
  },
  {
    id: "Hässleholm",
    alt: "Hässleholm kommun",
    fullLogo: HassleholmFull,
    logo: "",
  },
  {
    id: "Hörby",
    alt: "Hörby kommun",
    fullLogo: HorbyFull,
    logo: "",
  },
  {
    id: "Osby",
    alt: "Osby kommun",
    fullLogo: OsbyFull,
    logo: "",
  },
  {
    id: "Simrishamn",
    alt: "Simrishamn kommun",
    fullLogo: SimrishamnFull,
    logo: "",
  },
  {
    id: "ÖstraGöinge",
    alt: "Östra Göinge kommun",
    fullLogo: OstraGoingeFull,
    logo: "",
  },
  {
    id: "Sjöbo",
    alt: "Sjöbo kommun",
    fullLogo: SjoboFull,
    logo: "",
  },
  {
    id: "Skurup",
    alt: "Skurup kommun",
    fullLogo: SkurupFull,
    logo: "",
  },
  {
    id: "Ystad",
    alt: "Ystad kommun",
    fullLogo: YstadFull,
    logo: "",
  },
  {
    id: "Kristianstad",
    alt: "Kristianstad kommun",
    fullLogo: KristianstadFull,
    logo: "",
  },
  {
    id: "Tomelilla",
    alt: "Tomelilla kommun",
    fullLogo: TomelillaFull,
    logo: "",
  },
];

type HOSMunicipalityLogoProps = {
  id: string;
  customCssClass?: string;
} & ({ imgLink: string; fullLogo?: never } | { imgLink?: never; fullLogo?: boolean });

function HOSMunicipalityLogo({ id, customCssClass, fullLogo = true, imgLink }: HOSMunicipalityLogoProps) {
  if (imgLink) {
    return <embed src={imgLink} title={id} className={customCssClass} />;
  }

  if (!id) {
    return null;
  }

  const img = logos.find(logo => logo.id === id);

  if (!img) {
    return null;
  }

  const logo = fullLogo ? img.fullLogo : img.logo;

  return <embed src={logo} title={img.alt} className={customCssClass} />;
}

export default memo(HOSMunicipalityLogo);
